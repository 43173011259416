import React from 'react'
import LanguagePicker from '../languagePicker/languagePicker'
import Drawer from '../drawer/drawer'
import ThemeToggle from '../themeToggle/themeToggle'
import Link from '../../components/link'
import Logo from '../../assets/svg/logo.svg'

const Header = () => (
    <header style={{padding: '0 16px'}}>
        <nav >
            {/* <div className="navLHS">
                <Drawer />
            </div> */}
            <Link to={"/"} style={{textDecoration: 'none', color: 'inherit'}}> 
                <h1 style={{margin: 0}}><span style={{opacity: 0.1}}>サボリドアド·アドリア</span><br/>Adrià Saborido</h1>
                {/* <Logo className="navLogo" /> */}
            </Link>
            
            <div className="navRHS">
                <LanguagePicker />
                {/* <ThemeToggle /> */}
            </div>
        </nav>
    </header>
)

export default Header
