const NavLinks = [
    {
        id: 'homepage',
        path: '/',
    },
    {
        id: 'projects',
        path: '/projects',
    },
    {
        id: 'contact',
        path: '/contact/',
    },
]

export default NavLinks
