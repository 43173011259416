import React from 'react'

function Footer() {
    return (
        <footer>
            © {new Date().getFullYear()} Adrià Saborido
        </footer>
        
    )
}

export default Footer
